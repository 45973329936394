import React from 'react';
import LoginInputWrapper from 'components/Login/LoginInputWrapper';
import { loginValidationSchema } from 'components/Login/helpers';
import { LoginFormStyled, ContinueSection } from 'components/Login/LoginForm.styled';
import { FormikProps } from 'formik';

import { Button, Divider, TermsOfService } from 'src/js/nextgen/plushcare-components';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { appUrl } from 'src/js/utils';
import { useFeatureFlag } from 'src/js/abTesting/hooks';
import { FLAGS } from 'src/js/abTesting/flags';
import { useRouter } from 'next/router';

interface LoginFormProps {
  onSubmit: any;
  isPageLoading: boolean;
  onResetErrors: any;
}

interface FormValues {
  email: string;
  password: string;
}

const LoginForm = (props: LoginFormProps & FormikProps<FormValues>) => {
  const {
    onSubmit, isPageLoading, onResetErrors, isSubmitting,
  } = props;
  const router = useRouter();
  const isLoading = isPageLoading || isSubmitting;
  const { displayName, variant } = useAppVariant();
  const registrationUrl = variant === AppVariants.AccoladeCare
    ? appUrl.registration.enterprise
    : appUrl.registration.base;

  const [passwordlessAuthDecision] = useFeatureFlag(FLAGS.PASSWORDLESS_AUTH);
  return (
    <LoginFormStyled
      onSubmit={(e: any) => onSubmit(e)}
      validationSchema={loginValidationSchema}
      type="login"
    >
      <h2>Sign In.</h2>
      <p>
        Sign in to your
        {' '}
        {displayName}
        {' '}
        account below.
      </p>
      <LoginInputWrapper onFocus={onResetErrors} />
      <div className="forgot-password">
        <a href={appUrl.password.reset}> Forgot Password? </a>
      </div>
      <TermsOfService />
      <ContinueSection>
        <Button
          tabIndex={0}
          type="secondary-inverse"
          className="continue-button"
          isLoading={isLoading}
          buttonType="filled"
          text="Continue"
        />
        {passwordlessAuthDecision.enabled && variant === AppVariants.PlushCare
          && (
            <div>
              <Button type="secondary-inverse" onClick={() => router.push(appUrl.otpLogin.index)}>Sign in with a magic link</Button>
            </div>
          )}
        <Divider />
        <p className="sign-in-text">
          New to
          {' '}
          {displayName}
          ?
          {' '}
          <a href={registrationUrl}>Create an Account</a>
          .
        </p>
        {variant === AppVariants.AccoladeCare && (
          <Button
            tabIndex={0}
            type="secondary-tertiary"
            onClick={(e) => { e.preventDefault(); window.location.href = appUrl.authentication.healthplan; }}
            className="continue-button"
            text="Virtual health plan members"
          />
        )}
      </ContinueSection>
    </LoginFormStyled>
  );
};
export default LoginForm;
