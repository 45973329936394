/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { checkURLForError } from 'components/Login/helpers';

import { getNextRoute } from 'src/js/utils/location';
import PageError from 'src/js/nextgen/plushcare-components/PageError';
import Circle from 'src/js/nextgen/plushcare-components/Circle';
import LoginForm from 'components/Login/LoginForm';
import {
  appUrl, updateLocation,
} from 'src/js/utils';
import { useBrand } from 'src/js/nextgen/providers/BrandProvider';
import { useUserStripePaymentInfo } from 'src/js/endpoints/users';
import { useSignIn } from 'utils/hooks';

interface Props {
  redirectSuccessURL?: string,
}

interface LoginProps {
  email: string,
  password: string,
}

export const LoginContainer = (props: Props) => {
  const { support_phone } = useBrand();
  const signIn = useSignIn();
  useUserStripePaymentInfo();
  const [isPageLoading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  // check error in url
  const errorText = checkURLForError(router.pathname);
  // Getting errors about string[]
  const [pageError, setPageError] = useState<any | null>(errorText);

  const urlParams = router.query;
  const { redirectSuccessURL } = props;

  const nextRouteFromQueryParams = getNextRoute(router.query);
  useEffect(() => {
    setLoading(false);
    if (urlParams.msg) {
      setPageError(urlParams.msg);
      router.replace('/login');
    }
  }, [urlParams, router]);

  // since safari uses forward/back cache this will
  // reload the page instead of showing cached version
  useEffect(() => {
    window.onpageshow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);

  const handleResetErrors = () => {
    if (pageError) setPageError('');
  };
  const handleLogin = async ({ email, password }: LoginProps) => {
    setLoading(true);
    const postData = { email, password };
    try {
      await signIn(postData);
      // check if there is a next url to redirect to
      if (redirectSuccessURL) {
        if (redirectSuccessURL.includes('invite')) {
          updateLocation(redirectSuccessURL);
        } else {
          router.push(redirectSuccessURL);
        }
        // check for a redirect to the 'messaging-app'
      } else if (nextRouteFromQueryParams?.includes('messaging')) {
        // use window function for redirection since messaging-app is an app outside of the 'web-app'
        // and next.js router cannot redirect to an outside link
        // @ts-ignore
        window.location.replace(nextRouteFromQueryParams ?? appUrl.dashboard);
      } else {
        // @ts-ignore
        router.replace(nextRouteFromQueryParams ?? appUrl.dashboard);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        setPageError(`Please refresh the page and try again or call support to get help at ${support_phone}.`);
      } else {
        setPageError(error);
      }
      // for mobile users to be able to see the error msg on top of the page
      window.scrollTo(0, 0);
      setLoading(false);
    }
  };

  return (
    <>
      <Circle />
      <PageError text={pageError} />
      {/* @ts-ignore */}
      <LoginForm
        onResetErrors={handleResetErrors}
        onSubmit={handleLogin}
        isPageLoading={isPageLoading}
      />
    </>
  );
};

export default LoginContainer;
