import React from 'react';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';

import ErrorMessage from '../ErrorMessage';
import UnmaskedInputStyled from './UnmaskedInput.styled';

const UnmaskedInput = ({
  label,
  className,
  error,
  helperMessage,
  ...props
}) => {
  const { variant } = useAppVariant();
  return (
    <UnmaskedInputStyled
      className={`${className}${error && error.message ? ' input-error' : ''}${
        helperMessage ? ' helper-message' : ''
      }${variant === AppVariants.AccoladeCare ? ' acc-input' : ''}`}
    >
      {label && <div className="label">{label}</div>}
      <div className="input-container">
        <input className="input" {...props} />
        {helperMessage && <div className="helper-message">{helperMessage}</div>}
        <ErrorMessage message={(error && error.message) || ''} />
      </div>
    </UnmaskedInputStyled>
  );
};

UnmaskedInput.defaultProps = {
  label: '',
  className: '',
  placeholder: '',
  helperMessage: '',
  error: {},
};

export default UnmaskedInput;
