import { css, Global } from '@emotion/react';
import LoginContainer from 'components/Login/LoginContainer';
import { serverRedirectToNext, useLoginPageRedirect } from 'utils/redirects';
import WithNavigation from 'components/WithNavigation';
import { isUserAuthenticated } from 'utils/auth';
import { GetServerSideProps } from 'next';
import { StyledPage } from 'components/styled';

const globalStyles = css`
  html,
  body,
  #react-wrapper {
    width: 100%;
    height: 100%;
  }

  body.modal-open {
    overflow: hidden;
  }
`;

const Login = () => {
  const isLoading = useLoginPageRedirect();

  return (
    <>
      <Global styles={globalStyles} />
      <WithNavigation>
        <StyledPage>
          {!isLoading && (
            <LoginContainer />
          )}
        </StyledPage>
      </WithNavigation>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const authenticated = await isUserAuthenticated(ctx.req);
  if (authenticated) {
    return serverRedirectToNext(ctx.query);
  }
  return { props: {} };
};
export default Login;
