import React from 'react';
import { FormContext } from 'src/js/nextgen/forms';
import { EmailInput, PasswordInput } from 'components/Login/LoginForm.styled';

interface Props {
  onFocus: any
}

interface FormikDefault {
  formik: any,
}
const LoginInputWrapper = ({ onFocus }: Props) => {
  // TODO:: add more login validation

  const { formik } = React.useContext<FormikDefault>(FormContext);
  const { values, errors } = formik;
  const emailError = {
    message: errors.email ? errors.email : null,
    type: 'Please correct the error',
  };
  const passwordError = {
    message: errors.password ? errors.password : null,
    type: 'Please correct the error',
  };

  return (
    <>
      {/* @ts-ignore - larger refactor EmailInput needed */}
      <EmailInput
        name="email"
        label="Email"
        type="text"
        value={values.email || ''}
        placeholder="example@gmail.com"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={emailError}
      />
      {/* @ts-ignore - larger refactor PasswordInput needed */}
      <PasswordInput
        name="password"
        label="Password"
        type="password"
        value={values.password || ''}
        placeholder="******"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={passwordError}
        onFocus={onFocus}
      />
    </>
  );
};

export default LoginInputWrapper;
