import styled from '@emotion/styled';
import { colors } from '../helpers/plushcare-style-defaults';

export default styled.label`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 40px;
    padding: 12px 12px 9px;
    border: 1px solid ${colors.cloud};
    border-radius: 8px;
    margin: 24px 0 20px;
    font-size: 15px;
    line-height: 20px;
    color: ${props => props.theme.colors.foreground};

    &:focus {
      border-color: ${props => props.theme.colors.foreground};
      outline: none;
    }

    &::placeholder {
      color: ${colors.placeholderGray};
    }
  }

  &.acc-input {
    input {
      border-color: ${props => props.theme.colors.gray4};
      &:hover, &:focus {
        border-color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  &.helper-message input {
    margin-bottom: 0;
  }

  &.input-error input,
  &.acc-input.input-error input {
    border-color: ${colors.red};
    margin-bottom: 3px;
  }

  .label {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: ${props => props.theme.colors.foreground};
    text-align: left;

    & + .input-container > input {
      margin-top: 0;
    }
  }

  .input-container {
    width: 100%;
  }

  .helper-message {
    min-height: 15px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 15px;
  }

  .helper-message {
    color: ${colors.gray};

    & + .error-message {
      margin-top: 0;
    }
  }
`;
